<template>
  <section class="tech-certificate">
    <!-- title -->
    <h1 class="tech-certificate__title page__title">
      Регистрационное удостоверение
    </h1>
    <!-- tech docs content -->
    <div class="tech-certificate-wrapper page-wrapper">
      <div class="tech-certificate-header">
        <span class="tech-certificate-header__title">
          Поиск<span v-if="!$isMobile"> документа</span>:
        </span>
        <div class="tech-certificate-header__buttons">
          <div class="tech-certificate-header__kp">
            <base-button
              type="link-button"
              :active="active == 1"
              @clicked="active = 1"
            >
              по <span class="upp" style="margin-left: 2px">кп</span>
            </base-button>
          </div>
          <div class="tech-certificate-header__vendor">
            <base-button
              type="link-button"
              :active="active == 2"
              @clicked="active = 2"
            >
              по артикулу
            </base-button>
          </div>
        </div>
      </div>
      <div class="tech-certificate-content">
        <div
          class="tech-certificate-article"
          :class="{ filled: articles.length }"
          v-if="active == 2"
        >
          <div class="tech-certificate-article-field article-row _kp">
            <div class="offer-create-products-field article-row-field">
              <base-input
                :id="'offerCreateConterparty'"
                :placeholder="'Артикул или наименование'"
                :search="true"
                :suggestionWidth="380"
                :addProduct="true"
                :suggestions="articlesSuggestions"
                @setSuggestion="article = $event.value"
                @clear="
                  (articleToSearch = ''),
                    (article = ''),
                    (articlesSuggestions = [])
                "
                @input="getArticles"
                v-model="articleToSearch"
              />
            </div>
            <div class="offer-create-products-add article-row-button">
              <base-button :type="'outlined'" @clicked="addArticle">
                Добавить
              </base-button>
            </div>
          </div>
          <!-- table -->
          <certificates-table
            :articles="articles"
            @removeArticle="removeArticle"
          />
        </div>
        <div class="tech-certificate-kp">
          <div class="tech-certificate-kp-fields fields">
            <div
              class="tech-certificate-fields-row fields-row _number"
              v-if="active == 1"
            >
              <base-input
                :label="'№ КП'"
                :id="'techDocsKp'"
                :search="true"
                :suggestionWidth="380"
                :addProduct="true"
                :suggestions="kpSuggestions"
                @clear="(number = ''), (kpSuggestions = [])"
                @input="getOffers"
                v-model="number"
              />
            </div>
            <div class="tech-certificate-fields-row fields-row _email">
              <base-input
                :label="'Электронная почта'"
                input-type="email"
                :id="'techEmail'"
                v-model="email"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tech-certificate-buttons page-buttons _save _ru">
      <div class="tech-certificate-buttons__send _save__button">
        <base-button type="filled" :disabled="disabledSubmit" @clicked="send"
          >Отправить</base-button
        >
      </div>
      <div class="tech-certificate-buttons__download">
        <base-button
          type="outlined"
          :disabled="disabledSubmit"
          @clicked="download"
          >Скачать</base-button
        >
      </div>
    </div>
  </section>
  <base-modal :open="openModal" :text="modalText" @close="openModal = false" />
</template>

<script>
import { useMeta } from "vue-meta";
import CertificatesTable from "@/components/certificates/CertificatesTable.vue";
import { formatUrl } from "@/helpers/index";

export default {
  name: "Certificate",
  components: {
    CertificatesTable,
  },
  data() {
    return {
      active: 1,
      number: "",
      email: "",
      article: "",
      articleToSearch: "",
      openModal: false,
      modalText: "",
      articles: [],
      kpSuggestions: [],
      articlesSuggestions: [],
    };
  },
  setup() {
    useMeta({ title: "Регистрационное удостоверение" });
  },
  computed: {
    disabledSubmit() {
      if (this.active === 1) {
        return !this.number || !this.email;
      }

      return !this.email || !this.articles?.length || false;
    },
  },
  methods: {
    getOffers() {
      // general request
      this.kpSuggestions = [];
      let params = {
        [this.number && "search"]: this.number,
      };
      this.$api
        .get(`${this.$api.defaults.API}/offer`, { params })
        .then((res) => {
          // loop for get all fields values
          for (const o of res.data.results) {
            // get search suggestions
            if (this.number) {
              this.kpSuggestions.push({
                key: o.id,
                value: o.id,
              });
            }
          }
        })
        .catch((err) => new Error(err));
    },
    getArticles() {
      // general request
      this.articlesSuggestions = [];
      let params = {
        [this.articleToSearch && "search"]: this.articleToSearch,
      };
      this.$api
        .get(`${this.$api.defaults.API}/product`, { params })
        .then((res) => {
          // loop for get all fields values
          for (const o of res.data.results) {
            // get search suggestions
            if (this.articleToSearch) {
              this.articlesSuggestions.push({
                key: o.name,
                value: o.id,
              });
            }
          }
        })
        .catch((err) => new Error(err));
    },
    addArticle() {
      this.$store.dispatch("loading", true);
      this.$api
        .get(`${this.$api.defaults.API}/product/${this.article}`)
        .then((res) => {
          !this.articles.some((i) => i.id === res.data.id) &&
            this.articles.push(res.data);
          this.article = "";
          this.articleToSearch = "";
          this.articlesSuggestions = [];
          this.$store.dispatch("loading", false);
        });
    },
    async send() {
      const data = {
        send_to: this.email,
        articles: [],
      };

      // if with KP
      if (this.active === 1) {
        if (this.number && data.send_to) {
          // get article details
          await this.$api
            .get(`${this.$api.defaults.API}/offer/${this.number}`)
            .then(async (res) => {
              // get article details
              const articles = await res?.data?.offer_products;
              for (const a of articles) {
                await data?.articles.push(a.product);
              }
            })
            .catch((err) => {
              new Error(err);
              this.$store.dispatch("loading", false);
            });
        }
      } else {
        for (const a of this.articles) {
          await data.articles.push(a.id);
        }
      }
      // send to email
      if (data.articles.length && data.send_to) {
        this.$store.dispatch("loading", true);
        this.$api
          .post(`${this.$api.defaults.API}/permit-document/send-to-email`, data)
          .then(() => {
            (this.modalText = "Отправлено"), (this.openModal = true);
            this.$store.dispatch("loading", false);
          })
          .catch((err) => {
            new Error(err);
            this.$store.dispatch("loading", false);
          });
      }
    },
    async download() {
      const data = {
        articles: [],
      };

      // if with KP
      if (this.active === 1) {
        await this.$api
          .get(`${this.$api.defaults.API}/offer/${this.number}`)
          .then(async (res) => {
            // get article details
            const articles = await res?.data?.offer_products;
            for (const a of articles) {
              await data?.articles.push(a.product);
            }
          })
          .catch((err) => {
            new Error(err);
            this.$store.dispatch("loading", false);
          });
      } else {
        for (const a of this.articles) {
          await data.articles.push(a.id);
        }
      }

      // download file
      if (data?.articles?.length) {
        this.$store.dispatch("loading", true);

        this.$api
          .post(`${this.$api.defaults.API}/permit-document/download`, data)
          .then((res) => {
            window.open(formatUrl(res.data.url), "_blank");
            this.$store.dispatch("loading", false);
          })
          .catch((err) => {
            new Error(err);
            this.$store.dispatch("loading", false);
          });
      }
    },
    removeArticle(val) {
      const index = this.articles.findIndex((i) => i.article === val);
      this.articles.splice(index, 1);
    },
  },
};
</script>
